@import '../styles/globals.scss';

.header {
  background-color: $background-stepHeader;
  padding-top: 5*$spacing;
  padding-bottom: 2.5*$spacing;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
}
