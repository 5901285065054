@import '../styles/globals.scss';

.normal {
  color: $secondary-main;
  font-weight: $fontWeightMedium;
}

.disabled {
  color: rgba($secondary-main, 1-$action-disabledOpacity);
  font-weight: $fontWeightMedium;
}

.withNew {
  display: flex;
  align-items: baseline;
}

.centered {
  justify-content: center;
}
