@import '../styles/globals.scss';

.rowHeight {
  min-height: 2.1*$body1-fontSize;
}

.newTag {
  display: inline;
  margin-left: 2*$spacing;
}
