@import '../styles/globals.scss';

$avatar-size: 6rem;

.card,
.cardInactive {
  position: relative;
  overflow: visible;
  padding: 0.75*$avatar-size 0.5*$avatar-size 0.5*$avatar-size 0.5*$avatar-size;
  min-height: 22.5rem;
  transition: all $transitions-duration-shorter ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around
}

.card {
  border: $shape-borderWidth solid transparent;
}

.cardInactive {
  background-color: $background-dashboard;
  border: $shape-borderWidth solid $misc-selectedBorder;
}

.avatar {
  position: absolute;
  left: calc(50% - #{0.5*$avatar-size});
  top: -0.5*$avatar-size;
  width: $avatar-size;
  height: $avatar-size;
  box-shadow: 0 0 0 $shape-mediumBorderWidth $misc-selectedBorder !important;
}

.storyText {
  font-family: $h4-fontFamily;
  font-size: $h4-fontSize;
  font-weight: $fontWeightRegular;
  line-height: 1.2*$h4-lineHeight;
  letter-spacing: $h4-letterSpacing;
}

.storyRole {
  font-family: $h4-fontFamily;
  font-size: $h4-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $h4-lineHeight;
  letter-spacing: $h4-letterSpacing;
}
