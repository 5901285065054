@import '../styles/globals.scss';

.icon,
.iconDisabled {
  width: 3.75*$spacing;
  height: 3.75*$spacing;
}

.icon {
  color: $primary-contrastText;
  background-color: $primary-main;
}

.iconDisabled {
  color: rgba($primary-contrastText, 1 - $action-focusOpacity);
  background-color: $action-disabled;
}
