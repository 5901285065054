@import '../styles/globals.scss';

.cardRoot {
  overflow: visible;
  margin-top: 2*$spacing;
  margin-bottom: 2*$spacing;
  forced-color-adjust: none;
}

.titleRow {
  max-height: 3.5*$subtitle2-fontSize;
  overflow: hidden;
  font-family: $subtitle2-fontFamily;
  font-weight: $fontWeightMedium;
  font-size: $subtitle2-fontSize;
  line-height: $subtitle2-lineHeight;
  letter-spacing: $subtitle2-letterSpacing;
}

.current {
  height: 6.5*$subtitle2-fontSize;
  min-height: 6.5*$subtitle2-fontSize;
  max-height: 6.5*$subtitle2-fontSize;
}
.prevnext {
  height: 6*$subtitle2-fontSize;
  min-height: 6*$subtitle2-fontSize;
  max-height: 6*$subtitle2-fontSize;
}

.circle {
  width: $h5-fontSize;
  height: $h5-fontSize;
  right: -0.5*$h5-fontSize;
  margin-top: -0.5*$h5-fontSize;
  z-index: $zIndex-level1;
}
