@import '../styles/globals.scss';

.checkIcon {
  color: $misc-stepFinished;
}

.pastStep {
  color: $text-primary;
  background: $background-paper;
}

.futureStep {
  color: $primary-contrastText;
  background: $primary-main;
}

.linkActive,
.linkBtn {
  padding-top: 2.5*$spacing;
  padding-bottom: 2.5*$spacing;
  border-radius: 0;
  flex-grow: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.linkBtn {
  color: $secondary-main;
  background-color: $background-stepHeader;
}
.linkActive {
  color: $text-label !important;
  background-color: $background-stepActive;
}
