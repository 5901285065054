@import '../styles/globals.scss';

.button,
.textButton {
  color: $secondary-main;
  border: $shape-thinBorderWidth solid $background-card;
  box-shadow: $shadows-8;
  background: $background-card
    linear-gradient(to right,
      $background-chipLeft 0%,
      $background-card 100%
    );
  svg {
    width: 3rem;
    height: 3rem;
  }
  &:hover {
    border: $shape-thinBorderWidth solid $primary-main;
    background: darken($background-card, 0.5%)
      linear-gradient(to right,
        darken($background-chipLeft, 0.5%) 0%,
        darken($background-card, 0.5%) 100%
      );
    @media (hover: none) {
      background: transparent;
    }
  }
}

.button {
  padding: 1.5*$spacing 3*$spacing;
}
.textButton {
  padding: 1.5*$spacing 5.25*$spacing;
  min-height: 4.5rem;
}
