@import '../styles/globals.scss';

.tabItem {
  padding: 1.5*$spacing;
}

.clickable {
  cursor: pointer;
}

.current,
.currentFirst {
  color: $text-label;
  border-bottom: $shape-thinBorderWidth solid $greys-border;
}

.first,
.other {
  color: $secondary-main;
  background: $background-stepHeader;
  border-bottom: $shape-thinBorderWidth solid $primary-light1;
}

.current,
.other {
  border-left: $shape-thinBorderWidth solid $primary-light1;
}
