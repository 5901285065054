@import '../styles/globals.scss';

.subtitle {
  font-family: $subtitle2-fontFamily;
  font-size: $subtitle2-fontSize;
  font-weight: $subtitle2-fontWeight;
  line-height: 1.25*$subtitle2-lineHeight;
  letter-spacing: $subtitle2-letterSpacing;
}

.hiddenInput {
  display: none;
}
