@import '../styles/globals.scss';

.header {
  font-family: $h3-fontFamily;
  font-size: $h3-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $h3-lineHeight;
  letter-spacing: $h3-letterSpacing;
}

.selector,
.button {
  margin: 2*$spacing 2*$spacing 2*$spacing 0;
}

.button {
  min-width: 10rem;
}
