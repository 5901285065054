@import '../styles/globals.scss';

.content {
  padding-top: 4*$spacing;
}

.container {
  border-top: $shape-borderWidth solid $delimiterColor;
  flex-direction: column;
  @media (min-width: $breakpoints-values-md) {
    flex-direction: row;
  }
}

.tableTitle {
  padding-bottom: 1.5*$spacing;
}
