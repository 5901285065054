@import '../styles/globals.scss';

.button {
  padding: 2.25*$spacing 4*$spacing;
  opacity: 1 !important;
}
.button, .button:hover, .button:active {
  // derived from shadow-8 with less transparent color:
  box-shadow: 5px 5px 5px -3px $singleShadowColor, 8px 8px 10px 1px $singleShadowColor, 0px 0px 14px 2px $singleShadowColor;
}
