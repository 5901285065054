@import '../styles/globals.scss';

.root {
  height: 2.25*$spacing;
  border-radius: 1.125*$spacing;
}

.colorPrimary {
  background-color: $background-card;
}

.bar {
  border-radius: 1.125*$spacing;
  background-color: $misc-skillConfirmed;
}
