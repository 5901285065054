@import '../styles/globals.scss';

.bg {
  color: $primary-contrastText;
  background: linear-gradient(0deg,
    $secondary-text 0%,
    $primary-light4 100%
  ) $primary-light4;
}

.privacyNotice {
  font-family: $h4-fontFamily;
  font-size: $h4-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $h4-lineHeight;
  letter-spacing: $h4-letterSpacing;
}
