@import '../styles/globals.scss';

.card {
  height: 10*$subtitle2-fontSize;
  min-height: 10*$subtitle2-fontSize;
  max-height: 10*$subtitle2-fontSize;
}

.titleRow {
  max-height: 3.5*$subtitle2-fontSize;
  overflow: hidden;
}

.info {
  color: $text-label;
  font-family: $body1-fontFamily;
  font-weight: $body1-fontWeight;
  font-size: $body1-fontSize;
  line-height: $body1-lineHeight;
  letter-spacing: $body1-letterSpacing;
}
