@import '../styles/globals.scss';

.card,
.content {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: stretch;
}
.content {
  justify-content: center;
  background: linear-gradient(90deg,
    $background-chipLeft 0%,
    $background-card 100%
  ) $background-chipLeft;
}
.emptyBox {
  padding-top: $org-filter-height - 0.5rem;
}
.emptyMsg {
  color: $text-primary;
}

.cardTitle {
  padding-top: 1*$spacing;
  padding-bottom: 1*$spacing;
  min-height: 3.4*$subtitle1-fontSize;
}

.header {
  font-family: $subtitle1-fontFamily;
  font-size: $subtitle1-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $subtitle1-lineHeight;
  letter-spacing: $subtitle1-letterSpacing;
}

.filtersContainer {
  padding-left: $shape-cardPadding;
  padding-right: $shape-cardPadding;
}
