@import '../styles/globals.scss';

.panel,
.staticPanel {
  padding: 0 $shape-cardSpacing $shape-cardVSpacing $shape-cardSpacing;
  border-bottom: $shape-borderWidth solid $delimiterColor;
}

.staticPanel {
  display: flex;
  flex-wrap: wrap;
  @media (min-width: $breakpoints-values-lg) {
    display: block;
    border-bottom: none;
    border-right: $shape-borderWidth solid $delimiterColor;
  }
}

.panel {
  @media (min-width: $breakpoints-values-md) {
    flex: none;
    overflow-y: auto;
    max-height: 80vh;
    border-bottom: none;
    border-right: $shape-borderWidth solid $delimiterColor;
  }
}

.menu {
  flex: 1 1 0;
  @media (min-width: $breakpoints-values-md) {
    max-width: 20rem;
  }
}
