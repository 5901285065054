@import '../styles/globals.scss';

.targetButton {
  flex-direction: column;
  align-items: flex-end;
  p {
    padding-bottom: 0.5*$spacing;
  }
  @media (min-width: $breakpoints-values-md) {
    flex-direction: row;
    align-items: center;
    p {
      padding-right: 2*$spacing;
      padding-bottom: 0;
    }
  }
}
