@import '../styles/globals.scss';

.qtBtn {
  position: fixed;
  bottom: 0;
  right: 12.5%;
  padding: 3.75*$spacing 2.25*$spacing 3.25*$spacing 3.25*$spacing;
  font-family: $h3-fontFamily;
  font-size: 0.5*($h3-fontSize + $h4-fontSize);
  font-weight: $fontWeightMedium;
  line-height: 0.5*($h3-lineHeight + $h4-lineHeight);
  letter-spacing: 0.5*($h3-letterSpacing + $h4-letterSpacing);
}

.qtIcon {
  background: $action-disabled;
}
