@import '../styles/globals.scss';

.card {
  forced-color-adjust: none;
  border: $shape-borderWidth solid $misc-selectedBorder;
  margin-bottom: 3.5*$spacing;
}

.header {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 1*$spacing;
  padding-bottom: 1*$spacing;
}

.avatar {
  color: $misc-selectedBorder;
  font-size: 4rem;
  width: 4rem;
  height: 4rem;
}

.details {
  display: flex;
  flex-wrap: wrap;
  padding-bottom: 0;
}

.skillChip {
  margin: 2*$spacing 1.5*$spacing 0 0;
}

.footer {
  display: flex;
  flex-direction: column;
  background: $background-chipLeft
    linear-gradient(to right,
      $background-chipLeft 0%,
      $background-chipRight 100%
    );
}
