@import '../styles/globals.scss';

.iconButton {
  margin-left: 3*$spacing;
  margin-right: 1.5*$spacing;
}

.icon {
  font-size: $appbar-icon-fontsize;
}
