@import '../globals.scss';

.primary {
  background-color: $primary-main;
}

.series0 {
  background-color: $chart-series0;
}
.series1 {
  background-color: $chart-series1;
}
.series2 {
  background-color: $chart-series2;
}
.series3 {
  background-color: $chart-series3;
}
.series4 {
  background-color: $chart-series4;
}
.series5 {
  background-color: $chart-series5;
}
.series6 {
  background-color: $chart-series6;
}

.s0L1 {
  background: $primary-lightest;
}
.s0L2 {
  background: $primary-light;
}
.s0L3 {
  background: $secondary-main;
}
.s0L4 {
  background: $secondary-dark2;
}

.s1L1 {
  background: $chart-series1level1;
}
.s1L2 {
  background: $chart-series1level2;
}
.s1L3 {
  background: $chart-series1level3;
}
.s1L4 {
  background: $chart-series1level4;
}

.s2L1 {
  background: $chart-series2level1;
}
.s2L2 {
  background: $chart-series2level2;
}
.s2L3 {
  background: $chart-series2level3;
}
.s2L4 {
  background: $chart-series2level4;
}

.s3L1 {
  background: $chart-series3level1;
}
.s3L2 {
  background: $chart-series3level2;
}
.s3L3 {
  background: $chart-series3level3;
}
.s3L4 {
  background: $chart-series3level4;
}

.s4L1 {
  background: $chart-series4level1;
}
.s4L2 {
  background: $chart-series4level2;
}
.s4L3 {
  background: $chart-series4level3;
}
.s4L4 {
  background: $chart-series4level4;
}

.s5L1 {
  background: $chart-series5level1;
}
.s5L2 {
  background: $chart-series5level2;
}
.s5L3 {
  background: $chart-series5level3;
}
.s5L4 {
  background: $chart-series5level4;
}

.s6L1 {
  background: $chart-series6level1;
}
.s6L2 {
  background: $chart-series6level2;
}
.s6L3 {
  background: $chart-series6level3;
}
.s6L4 {
  background: $chart-series6level4;
}
