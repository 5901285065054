@import '../styles/globals.scss';

.first {
  border-top: $shape-thinBorderWidth solid $divider;
}

.last {
  border-bottom: $shape-thinBorderWidth solid $divider;
}

.details {
  display: block;
}
