@import '../styles/globals.scss';

.infoCard {
  background: $background-card;
  padding: $shape-cardPadding;
}

.infoCard,
.bonusLine,
.subheader {
  font-family: $subtitle2-fontFamily;
  font-size: $subtitle2-fontSize;
  font-weight: $subtitle2-fontWeight;
  line-height: $subtitle2-lineHeight;
  letter-spacing: $subtitle2-letterSpacing;
}
.subheader {
  font-weight: $fontWeightBold;
}

.targetLimitMsg {
  font-family: $h4-fontFamily;
  font-size: $h4-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $h4-lineHeight;
  letter-spacing: $h4-letterSpacing;
  text-align: left;
}
