@import '../styles/globals.scss';

.chartContainer {
  position: relative;
  display: flex;
  justify-content: center;
}

.levelDescription {
  max-width: 62rem;
}
