@import '../styles/globals.scss';

.item {
  padding: $shape-cardVSpacing $shape-cardSpacing !important;
}

.card {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: stretch;
  @media (forced-colors: active) {
    border: $shape-borderWidth solid ButtonBorder;
  }
}

.newCountMsg {
  color: $info-main;
  font-weight: $fontWeightMedium;
  text-align: right;
}

.button {
  padding-top: 1.5*$spacing;
  padding-bottom: 1.5*$spacing;
}
