@import '../styles/globals.scss';

.header {
  padding-bottom: 1*$spacing;
}

.hidden,
.hidden2 {
  position: absolute;
  top: -9999px;
  width: 1478px;
}
.hidden {
  left: -9999px;
}
.hidden2 {
  right: -9999px;
}
