@import '../styles/globals.scss';

.avatar {
  display: none;
  @media (min-width: $breakpoints-values-xl) {
    display: block;
  }
}

.titleContainer {
  height: 2.3*$subtitle2-fontSize;
  min-height: 2.3*$subtitle2-fontSize;
  max-height: 2.3*$subtitle2-fontSize;
}

.titleRow,
.delegatorRow {
  max-height: 2.3*$subtitle2-fontSize;
  overflow: hidden;
}
.delegatorRow {
  line-height: $subtitle1-lineHeight;
}

.infoContainer {
  padding-top: 0.25rem;
  height: 4.75*$body1-fontSize + 0.25rem;
  min-height: 4.75*$body1-fontSize + 0.25rem;
  max-height: 4.75*$body1-fontSize + 0.25rem;
  overflow: hidden;
}

.infoContainer2 {
  padding-top: 0.25rem;
  padding-bottom: 0.75rem;
  height: 4.5*$body2-fontSize + 1rem;
  min-height: 4.5*$body2-fontSize + 1rem;
  max-height: 4.5*$body2-fontSize + 1rem;
  overflow: hidden;
}

.jobRow {
  max-height: 2.75*$body1-fontSize;
  overflow: hidden;
}

.jobRow2 {
  max-height: 2.75*$body2-fontSize;
  overflow: hidden;
}

.zoomInBtn {
  padding: 0.75*$spacing;
}
