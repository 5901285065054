@import '../styles/globals.scss';

.openReqsFilter {
  min-width: 12rem;
}

.supervisoryFilter {
  min-width: 13rem;
}

.matchRateFilter {
  min-width: 17rem;
  max-width: 34rem;
}
