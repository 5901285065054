@import '../styles/globals.scss';

.timestamp {
  color: $greys-switch;
  font-family: $subtitle2-fontFamily;
  font-size: $subtitle2-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $subtitle2-lineHeight;
  letter-spacing: $subtitle2-letterSpacing;
}
