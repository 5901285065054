@import '../styles/globals.scss';

.header {
  color: $primary-contrastText;
  padding: 0.75*$shape-cardPadding $shape-cardPadding;
  background: linear-gradient(0deg,
    $primary-light1 0%,
    $primary-light2 100%
  ) $primary-light2;
}
