@import '../styles/globals.scss';

.footer {
  justify-content: center;
}

.builder {
  padding-top: 0;
  padding-bottom: 4*$spacing;
}

.builderPending {
  padding-top: 4*$spacing;
  padding-bottom: 4*$spacing;
}

.quickTour {
  padding-top: 2.5*$spacing;
  padding-bottom: 2.5*$spacing;
  border-top: $shape-thinBorderWidth solid $misc-tourFooterBorder;
}

.prevButton,
.nextButton {
  font-family: $button-fontFamily;
  font-size: $button-fontSize;
  font-weight: $button-fontWeight;
  line-height: $button-lineHeight;
  letter-spacing: $button-letterSpacing;
  min-width: 10rem;
  margin: 0 2.5*$spacing;
}

.prevButton {
  color: $primary-main;
  border-width: $shape-thinBorderWidth !important;
  &:hover {
    border-width: $shape-thinBorderWidth !important;
  }
}

.nextButton {
  padding-top: 1.125*$spacing;
  padding-bottom: 1.125*$spacing;
}
