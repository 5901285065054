@import '../styles/globals.scss';

.accordion {
  padding-left: 6*$spacing;
  background: linear-gradient(0deg,
    $background-card 0%,
    $background-chipLeft 100%
  ) $background-card;
  border-bottom: $shape-thinBorderWidth solid $borderColor;
  &:last-child {
    border-bottom: none;
  }
}

.summary {
  margin: 0.625*$spacing 0;
  div:first-of-type {
    margin: 0;
  }
}

.details {
  padding-top: 0;
  margin-left: -1.25*$spacing;
  margin-right: -1.25*$spacing;
}
