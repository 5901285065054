@import '../styles/globals.scss';

.item {
  padding: $shape-cardVSpacing $shape-cardSpacing !important;
  align-self: stretch;
}

.avatar,
.box {
  border-radius: $shape-borderRadius;
}

.avatar {
  border: $shape-thickBorderWidth dotted $misc-selectedBorder;
  background-color: $background-button;
  color: $misc-selectedBorder;
}

.box {
  border: $shape-thickBorderWidth solid $misc-selectedBorder;
  background-color: $background-paper;
}
