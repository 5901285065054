@import '../styles/globals.scss';

.header {
  padding-top: 5*$spacing;
  padding-bottom: 3*$spacing;
  padding-right: 7*$spacing;
}

.content {
  padding: 0;
}

.footer {
  padding: 2.75*$spacing;
  justify-content: center;
}

.button {
  padding-top: 1*$spacing;
  padding-bottom: 1*$spacing;
  margin: 0 15%;
}
