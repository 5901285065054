@import '../styles/globals.scss';

.section {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 0;
  margin-bottom: -3*$spacing;
}
