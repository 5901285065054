@import 'slick.scss';
@import '~@empathco/ui-components/src/styles/index.scss';

@for $i from 0 through 100 {
  .bar-width-#{$i} {
    width: $i * 1%;
  }
  .bar-height-#{$i} {
    height: $i * 1%;
  }
}
