@import '../styles/globals.scss';

.icon,
.activityIcon {
  @media (forced-colors: active) {
    forced-color-adjust: none;
    filter: invert(1) grayscale(1);
  }
}

.icon {
  min-height: 5.5rem;
  max-height: 5.5rem;
  height: 5.5rem;
}

.smallIcon {
  margin-right: 1*$spacing;
}

.activityIcon {
  min-height: 4rem;
  max-height: 4rem;
  height: 4rem;
  min-width: 4rem;
  max-width: 4rem;
  width: 4rem;
  color: $primary-main;
}

.disabledIcon {
  opacity: $action-disabledOpacity;
}

.txt {
  font-family: $h4-fontFamily;
  font-size: $h4-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $subtitle1-lineHeight;
  letter-spacing: $h4-letterSpacing;
}

.subtitle {
  max-width: 18rem;
  text-align: center;
}
