@import '../styles/globals.scss';

.content {
  display: flex;
}

.searchInput {
  min-width: 32rem;
  max-width: 32rem;
}

.confirmButton {
  padding: 1.125*$spacing 5.5*$spacing;
}
