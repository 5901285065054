@import '../styles/globals.scss';

.avatar {
  color: $text-primary;
  background: $greys-iconBg;
  svg {
    font-size: $h2-fontSize;
  }
}

.divider {
  border-color: $greys-border;
  margin: 1*$spacing 2*$spacing;
}

.listItemText {
  padding-right: 1.5*$spacing;
}

.listItemTypography {
  font-weight: $fontWeightMedium;
}
