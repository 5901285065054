@import '../styles/globals.scss';

.avatar {
  color: $primary-contrastText;
  background: $misc-selectedBorder;
  padding-left: 0.125*$spacing;
  padding-bottom: 0.5*$spacing;
  svg {
    font-size: 1.9rem;
    :nth-child(2) {
      opacity: 0.95;
    }
  }
}
