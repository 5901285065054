@import '../styles/globals.scss';

.chip {
  @media (forced-colors: active) {
    border: $shape-borderWidth solid ButtonBorder;
  }
}

.chipWithLevel {
  forced-color-adjust: none;
}

.primary,
.info {
  display: flex;
  align-items: center;
}

.primary {
  svg {
    fill: $primary-light;
  }
}

.info {
  svg {
    fill: $info-lightest;
  }
}

.zeroElevation {
  box-shadow: none;
  border: $shape-borderWidth solid $background-stepActive;
}
