@import '../styles/globals.scss';

.chip {
  margin: 0.5*$spacing 1.5*$spacing;
}

.reset {
  font-style: italic;
  font-weight: $fontWeightRegular;
}

.scrollable {
  max-height: 24rem;
  overflow-y: auto;
  // Firefox:
  scrollbar-color: $background-dot $background-paper;
}

// Chrome, Edge, Safari:
.scrollable::-webkit-scrollbar {
  width: 0.66rem;
}
.scrollable::-webkit-scrollbar-track {
  background: $background-paper;
  border-radius: 0.33rem;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: $background-dot;
  border-radius: 0.33rem;
}
