@import '../styles/globals.scss';

.list {
  flex: 1 1 0;
}

.listItem {
  padding: 0 3*$spacing;
}

.icon {
  margin-left: 1*$spacing;
}

.alert {
  flex-grow: 1;
}

.skillItem {
  flex: 1 1 0;
  display: flex;
  align-items: center;
}
