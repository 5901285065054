@import '../styles/globals.scss';

.titleContainer {
  height: 2.3*$subtitle2-fontSize;
  min-height: 2.3*$subtitle2-fontSize;
  max-height: 2.3*$subtitle2-fontSize;
}

.titleRow {
  max-height: 2.3*$subtitle2-fontSize;
  overflow: hidden;
}

.infoContainer {
  padding-top: 0.25rem;
  height: 4.75*$body1-fontSize + 0.25rem;
  min-height: 4.75*$body1-fontSize + 0.25rem;
  max-height: 4.75*$body1-fontSize + 0.25rem;
  overflow: hidden;
}

.jobRow {
  max-height: 2.75*$body1-fontSize;
  overflow: hidden;
}
