@import '../styles/globals.scss';

.card,
.ownCard {
  display: flex;
  flex: 1 0 0;
  flex-direction: column;
}
.card {
  padding: 2.5*$spacing 2.5*$spacing 1.5*$spacing 2.5*$spacing;
  height: 8.5*$subtitle2-fontSize;
  min-height: 8.5*$subtitle2-fontSize;
  max-height: 8.5*$subtitle2-fontSize;
}
.ownCard {
  padding: 1.25*$spacing 1.25*$spacing 2.25*$spacing 2.5*$spacing;
  height: 10.5*$subtitle2-fontSize;
  min-height: 10.5*$subtitle2-fontSize;
  max-height: 10.5*$subtitle2-fontSize;
}

.titleRow,
.titleDevPlan {
  overflow: hidden;
  font-weight: $fontWeightMedium;
}

.titleRow {
  height: 2.25*$subtitle2-fontSize;
  min-height: 2.25*$subtitle2-fontSize;
  max-height: 2.25*$subtitle2-fontSize;
}
.titleDevPlan {
  height: 2.5*$subtitle1-fontSize;
  min-height: 2.5*$subtitle1-fontSize;
  max-height: 2.5*$subtitle1-fontSize;
}

.disabledText {
  color: rgba($secondary-main, 1-$action-disabledOpacity);
}
