@import '../styles/globals.scss';

.label {
  text-transform: uppercase;
  white-space: nowrap;
}

.alone {
  background: linear-gradient(0deg,
    $background-stepHeader 0%,
    $background-paper 100%
  ) $background-stepHeader;
  margin-top: -$spacing;
  margin-bottom: 0;
}

.avatar {
  color: $misc-selectedBorder;
  font-size: 5rem;
  width: 5rem;
  height: 5rem;
}
