@import '../styles/globals.scss';

.chip {
  @media (forced-colors: active) {
    border: $shape-borderWidth solid ButtonBorder;
  }
}

.dragging {
  box-shadow: 0 0 0 $shape-mediumBorderWidth $misc-draggedBorder !important;
  color: transparent !important;
  background: transparent !important;
  svg {
    fill: transparent !important;
    background-color: transparent !important;
  }
}

.selected {
  margin: 1*$spacing;
}

.voteIcon,
.voteIconActive {
  font-size: 2.25*$h5-fontSize;
  margin-left: 2*$spacing;
  margin-right: 0.5*$spacing;
}

.voteIcon {
  color: $info-lightest;
}

.voteIconActive {
  color: $primary-contrastText;
}

.primary,
.info {
  display: flex;
  align-items: center;
  padding-right: 1*$spacing;
}

.primary {
  svg {
    fill: $primary-light;
  }
}

.info {
  svg {
    fill: $info-lightest;
  }
}
