@import '../styles/globals.scss';

.header {
  font-family: $h3-fontFamily;
  font-size: $h3-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $h3-lineHeight;
  letter-spacing: $h3-letterSpacing;
}

.subheader {
  padding-top: 6*$spacing;
  font-family: $subtitle1-fontFamily;
  font-size: $subtitle1-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $subtitle1-lineHeight;
  letter-spacing: $subtitle1-letterSpacing;
}
