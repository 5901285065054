@import '../styles/globals.scss';

.chart,
.chartPreview {
  display: flex;
  flex-grow: 1;
}

.chart {
  min-height: 24rem;
  max-height: 24rem;
}

.chartPreview {
  min-height: 8rem;
  max-height: 8rem;
  canvas {
    cursor: pointer !important;
  }
}

.tooltip {
  text-align: left;
  padding: 1*$spacing 1*$spacing 0 1*$spacing;
  display: flex;
  flex-direction: column;
}

.tooltipHeader {
  font-family: $subtitle2-fontFamily;
  font-size: $subtitle2-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $subtitle2-lineHeight;
  letter-spacing: $subtitle2-letterSpacing;
  padding-bottom: 1.75*$spacing;
  display: flex;
  align-items: center;
}

.tooltipHeaderText {
  flex: 1 0 0;
}

.tooltipHeaderValue {
  padding-left: 3*$spacing;
}

.tooltipHeaderPadding {
  padding-top: 2*$spacing;
  padding-bottom: 0;
}

.tooltipSubheader {
  font-family: $caption-fontFamily;
  font-size: $caption-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $caption-lineHeight;
  letter-spacing: $caption-letterSpacing;
  padding-bottom: 0.25*$spacing;
  text-transform: uppercase;
  text-align: right;
  color: $info-caption;
}

.tooltipLine {
  font-family: $h5-fontFamily;
  font-size: $h5-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $h5-lineHeight;
  letter-spacing: $h5-letterSpacing;
  padding-bottom: 0.75*$spacing;
  display: flex;
  align-items: center;
}

.tooltipTitle {
  flex: 1 1 0;
  display: flex;
  align-items: center;
  padding-right: 3*$spacing;
  max-width: 25rem;
  white-space: initial;
}

.tooltipPercent {
  margin-left: 2*$spacing;
  width: 3*$h5-fontSize;
  text-align: right;
  font-weight: $fontWeightRegular;
}

.tooltipValue {
  display: flex;
  width: 15rem;
  align-items: center;
  padding-top: 0.25*$spacing;
  padding-bottom: 0.25*$spacing;
}

.tooltipNumber {
  flex: 1 0 0;
  text-align: right;
}

.tooltipBar {
  border-radius: $shape-mediumBorderRadius;
  height: 1.5*$h5-fontSize;
  margin-right: 2*$spacing;
}

.tooltipInfo {
  color: $info-caption;
  font-family: $body2-fontFamily;
  font-size: $body2-fontSize;
  font-weight: $body2-fontWeight;
  line-height: $body2-lineHeight;
  letter-spacing: $body2-letterSpacing;
  margin-top: -0.75*$spacing;
  margin-bottom: 0.75*$spacing;
}

.marker {
  height: $body2-fontSize;
  width: $body2-fontSize;
  min-height: $body2-fontSize;
  min-width: $body2-fontSize;
  max-height: $body2-fontSize;
  max-width: $body2-fontSize;
  border-radius: 0.5*$body2-fontSize;
  margin-right: 0.75*$body2-fontSize;
}
