@import '../styles/globals.scss';

.icon1 {
  color: $primary-lightest;
}

.icon2 {
  color: $primary-lighter;
}

.icon3 {
  color: $misc-tagCourse;
}

.icon4 {
  color: $primary-main;
}

.icon5 {
  color: $misc-tagActive;
}
