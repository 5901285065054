@import '../styles/globals.scss';

.paper {
  border: $shape-thinBorderWidth solid $misc-selectBorder;
}

.list {
  padding-top: 0;
  padding-bottom: 0;
}

.divider {
  border-bottom-width: $shape-mediumBorderWidth;
}
