@import '../styles/globals.scss';

.orgLabel {
  font-weight: $fontWeightMedium;
  text-transform: uppercase;
  z-index: $zIndex-level1;
}

.orgFilter {
  margin-bottom: -$org-filter-height;
  height: $org-filter-height;
  min-height: $org-filter-height;
  max-height: $org-filter-height;
  overflow-y: visible;
}

.employeeRatedFilter {
  min-width: 19.5rem;
}

.foldingButton {
  margin-left: 1*$spacing;
  padding: 0.625*$spacing;
  min-width: 0;
}

.jobSearch {
  min-width: 14rem;
}
