@import '../styles/globals.scss';

.chart {
  display: flex;
  flex-grow: 1;
  min-width: 20rem;
  max-width: 36rem;
  min-height: 12rem;
  max-height: 12rem;
}
