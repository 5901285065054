@import '../styles/globals.scss';

.reqSkillsTitle {
  font-family: $body2-fontFamily;
  font-weight: $fontWeightBold;
  font-size: $body2-fontSize;
  line-height: $body2-lineHeight;
  letter-spacing: $body2-letterSpacing;
  text-transform: uppercase;
}
