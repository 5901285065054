@import '../styles/globals.scss';

.rowHeight {
  min-height: 2.1*$body1-fontSize;
}

.nextIcon {
  display: inline-flex;
}

.icons1 tr td:first-of-type {
  width: 2.3rem;
  max-width: 2.3rem;
}

.icons2 tr td:first-of-type {
  width: 4rem;
  max-width: 4rem;
}

.icons3 tr td:first-of-type {
  width: 5.5rem;
  max-width: 5.5rem;
}

.icons0 tr th, .icons0 tr td,
.icons1 tr th, .icons1 tr td,
.icons2 tr th, .icons2 tr td,
.icons3 tr th, .icons3 tr td {
  padding-left: 1*$spacing;
  padding-right: 1*$spacing;
}

.newTag {
  display: inline;
  margin-left: 2*$spacing;
}
