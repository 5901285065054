@import '../styles/globals.scss';

.dialogContent {
  padding: 0;
}

.detailsButton {
  padding: 1*$spacing 2.5*$spacing;
  margin: 2*$spacing 2.25*$h2-fontSize 2*$spacing 4*$spacing;
}
