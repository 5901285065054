@import '../styles/globals.scss';

.card {
  height: 100%; // TODO: make all cards in a row equal in height
}

.cardBorder {
  border: $shape-thinBorderWidth solid $misc-selectedBorder;
}

.cardBg {
  padding-top: 1*$spacing;
  padding-left: 2.5*$spacing;
  padding-right: 2.5*$spacing;
  padding-bottom: 2*$spacing;
  background: $background-chipLeft
    linear-gradient(0deg,
      $background-chipLeft 0%,
      $background-chipRight 100%
    );
}

.divider {
  border-color: $greys-barBorder;
}

.saveBtn {
  margin-top: -0.5*$spacing;
  margin-bottom: -0.5*$spacing;
  margin-left: -1.5*$spacing;
}

.tag {
  height: 4.25*$spacing;
  min-height: 4.25*$spacing;
}
