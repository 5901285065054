@import '../styles/globals.scss';

.tckr {
  background: linear-gradient(90deg,
    $secondary-icon 0%,
    $secondary-shifted 100%
  ) $secondary-main;
}

.warn {
  background: $error-light;
}

.ln {
  font-family: $subtitle1-fontFamily;
  font-size: 1.1*$subtitle1-fontSize;
  font-weight: $fontWeightRegular;
  line-height: 1.2*$subtitle1-lineHeight;
  letter-spacing: 1.1*$subtitle1-letterSpacing;
  margin-bottom: 4*$spacing;
}
