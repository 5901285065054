@import '../styles/globals.scss';

.topSection{
  display: flex;
  flex-direction: column;
}

.targetIcon {
  margin-right: 1*$spacing;
}

.foldingButton {
  margin-left: 6*$spacing;
  padding: 0.625*$spacing;
  min-width: 0;
}

.coursesButton {
  padding: 1.2*$spacing 3*$spacing;
}

.confirmButton {
  padding: 1.2*$spacing 7*$spacing;
}
