@import '../styles/globals.scss';

.left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding-right: 1.5*$spacing;
  background: $background-chipLeft
    linear-gradient(to right,
      $background-chipLeft 0%,
      $background-chipRight 100%
    );
}

.right {
  flex: 1 1 0;
  padding-left: 0;
  padding-right: 0;
}

.teamTitle {
  padding-left: 1.5*$shape-cardSpacing;
  padding-right: 1.5*$shape-cardSpacing;
}

.teamIcon {
  color: $background-paper;
  background-color: $misc-selectBorder;
}

.teamTitleText {
  font-family: $subtitle1-fontFamily;
  font-size: 1.1*$subtitle1-fontSize;
  font-weight: $fontWeightMedium;
  line-height: 1.1*$subtitle1-lineHeight;
  letter-spacing: 1.1*$subtitle1-letterSpacing;
}
