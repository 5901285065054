@import '../styles/globals.scss';

.chart,
.chartPreview {
  display: flex;
  flex-grow: 1;
}

.chart {
  min-height: 30rem;
  max-height: 30rem;
}

.chartPreview {
  min-height: 8rem;
  max-height: 8rem;
  canvas {
    cursor: pointer !important;
  }
}

.chartNarrow {
  display: flex;
  flex-grow: 1;
  min-height: 24rem;
  max-height: 24rem;
}

.tooltip {
  padding: 1*$spacing 1.5*$spacing;
}

.header,
.headerMgr,
.line {
  display: flex;
  align-items: center;
  color: $text-primary;
}

.header,
.headerMgr {
  font-family: $subtitle2-fontFamily;
  font-size: $subtitle2-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $subtitle2-lineHeight;
  letter-spacing: $subtitle2-letterSpacing;
}
.header {
  padding-bottom: 0.5*$spacing;
}
.headerMgr {
  padding-bottom: 2.25*$spacing;
}

.percent {
  font-family: $subtitle1-fontFamily;
  font-size: $subtitle1-fontSize;
  font-weight: $subtitle1-fontWeight;
  line-height: $subtitle1-lineHeight;
  letter-spacing: $subtitle1-letterSpacing;
}

.total {
  color: $text-secondary;
}
.total,
.totalCount {
  padding-bottom: 2.25*$spacing;
}
.total,
.totalCount,
.totalMessage,
.openReqDays {
  font-family: $body2-fontFamily;
  font-size: $body2-fontSize;
  font-weight: $body2-fontWeight;
  line-height: $body2-lineHeight;
  letter-spacing: $body2-letterSpacing;
}
.totalMessage {
  color: $text-label;
  padding-left: 1.67rem;
  padding-top: 1.5*$spacing;
}

.tooltipSubheader {
  font-family: $caption-fontFamily;
  font-size: $caption-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $caption-lineHeight;
  letter-spacing: $caption-letterSpacing;
  padding-top: 1.25*$spacing;
  padding-bottom: 0.25*$spacing;
  text-transform: uppercase;
  color: $info-caption;
}

.line,
.courseTitle {
  font-family: $body1-fontFamily;
  font-size: $body1-fontSize;
  line-height: $body1-lineHeight;
  letter-spacing: $body1-letterSpacing;
}
.line {
  font-weight: $body1-fontWeight;
  padding-bottom: 1.5*$spacing;
}
.courseTitle {
  font-weight: $fontWeightMedium;
}

.marker {
  height: $body2-fontSize;
  width: $body2-fontSize;
  min-height: $body2-fontSize;
  min-width: $body2-fontSize;
  max-height: $body2-fontSize;
  max-width: $body2-fontSize;
  border-radius: $shape-smallBorderRadius;
}
.markerTop {
  height: $body1-fontSize;
  width: $body1-fontSize;
  min-height: $body1-fontSize;
  min-width: $body1-fontSize;
  max-height: $body1-fontSize;
  max-width: $body1-fontSize;
  border-radius: $shape-smallBorderRadius;
  margin-right: 0.67rem;
}

.headline,
.label {
  flex: 1 0 0;
  padding-right: 3*$spacing;
}
.label {
  padding-left: 1.75*$spacing;
}

.count {
  font-weight: $fontWeightMedium;
}
