@import '../styles/globals.scss';

.chart,
.chartPreview {
  display: flex;
  flex-grow: 1;
}

.chart {
  min-height: 33rem;
  max-height: 33rem;
}

.chartPreview {
  min-height: 8rem;
  max-height: 8rem;
  canvas {
    cursor: pointer !important;
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  padding: 1*$spacing 1.75*$spacing;
}

.tooltipTitle {
  font-family: $subtitle1-fontFamily;
  font-size: $subtitle1-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $subtitle1-lineHeight;
  letter-spacing: $subtitle1-letterSpacing;
  padding-bottom: 1.75*$spacing;
}

.tooltipLine {
  flex: 1 0 0;
  display: flex;
  font-family: $h5-fontFamily;
  font-size: $h5-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $h5-lineHeight;
  letter-spacing: $h5-letterSpacing;
  padding-bottom: 0.75*$spacing;
  align-items: center;
}

.tooltipValue {
  flex: 1 0 0;
  align-self: flex-end;
  text-align: right;
  padding-left: 2.5*$spacing;
}

.tooltipHistory {
  display: flex;
  justify-content: center;
  height: 12rem;
  min-height: 12rem;
  max-height: 12rem;
  padding-top: 2*$spacing;
  font-family: $body2-fontFamily;
  font-size: $body2-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $body2-lineHeight;
  letter-spacing: $body2-letterSpacing;
}
.tooltipColumn {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  padding: 0 1*$spacing;
}
.tooltipCaption {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.tooltipBar {
  width: 2*$spacing;
  min-width: 2*$spacing;
  max-width: 2*$spacing;
  border-radius: $shape-smallBorderRadius $shape-smallBorderRadius 0 0;
}
.tooltipAxis {
  padding-top: 0.5*$spacing;
}

.marker {
  height: $body2-fontSize;
  width: $body2-fontSize;
  min-height: $body2-fontSize;
  min-width: $body2-fontSize;
  max-height: $body2-fontSize;
  max-width: $body2-fontSize;
  border-radius: 0.5*$body2-fontSize;
  margin-right: 0.75*$body2-fontSize;
}

.cardBackground {
  background-color: $background-card;
}
.barGradient {
  background: linear-gradient(0deg,
    $chart-barGradientBottom 0%,
    $chart-barGradientTop 100%
  ) $chart-barGradientTop;
}
.inferred {
  border: $shape-borderWidth solid $primary-main;
  background: repeating-linear-gradient(135deg,
    $primary-main 0,
    $primary-main $shape-borderWidth,
    transparent 0,
    transparent 50%
  ) $background-paper;
  background-size: 3.25*$shape-borderWidth 3.25*$shape-borderWidth;
}
