@import '../styles/globals.scss';

.avatar,
.avatarGrey {
  color: $primary-contrastText;
  padding-bottom: 0.25*$spacing;
  svg {
    font-size: 2.15rem;
    :nth-child(2) {
      opacity: 0.95;
    }
  }
}

.avatar {
  background: $primary-main;
}

.avatarGrey {
  background: $misc-selectedBorder;
}
