@import '../styles/globals.scss';

.empty,
.text,
.internal,
.uopx,
.coursera,
.udemy {
  height: 2.25rem;
  max-height: 2.25rem;
}

.coursera {
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
}

.udemy {
  width: 3.5rem;
  min-width: 3.5rem;
  max-width: 3.5rem;
}

.skillsoft {
  width: 5rem;
  min-width: 5rem;
  max-width: 5rem;
}

.internal {
  max-width: 9rem;
}

.text {
  display: flex;
  align-items: center;
  font-weight: $fontWeightMedium;
}
