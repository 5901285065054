@import '../styles/globals.scss';

.root {
  display: flex;
  align-items: center;
  margin-left: -1.75*$spacing;
  padding-right: 3.5*$spacing;
}

.icon,
.iconInactive {
  margin-right: 1*$spacing;
}

.iconInactive {
  color: $secondary-main;
}
