@import '../styles/globals.scss';

.footer {
  flex: 1 0 0;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2*$spacing;
  padding-bottom: 2*$spacing !important;
}

.button {
  font-weight: $fontWeightBold;
}

.disabled {
  font-family: $button-fontFamily;
  font-size: $body1-fontSize;
  font-weight: $fontWeightBold;
  line-height: $button-lineHeight;
  letter-spacing: $button-letterSpacing;
}
