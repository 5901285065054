@import '../styles/globals.scss';

.avatar,
.disabled,
.hidden {
  width: 1.15*$h4-fontSize;
  height: 1.15*$h4-fontSize;
  svg {
    font-size: 1*$h4-fontSize;
  }
}

.avatar {
  color: $primary-contrastText;
  background: $primary-main;
}

.disabled {
  color: $primary-contrastText;
  background: $action-disabled;
}

.hidden {
  color: transparent;
  background-color: transparent;
}
