@import '../styles/globals.scss';

.chart,
.chartMax {
  display: flex;
  flex-grow: 1;
}

.chart {
  min-height: 37rem;
  max-height: 37rem;
}

.chartMax {
  min-height: 56rem;
  max-height: 56rem;
}

.button {
  display: flex;
  align-items: center;
}
