@import '../styles/globals.scss';

.filters {
  padding-top: 1.75*$spacing !important;
  padding-bottom: 1*$spacing !important;
}

.modifiedBorder {
  border: $shape-borderWidth solid $chart-series5;
}

.sectionHeader {
  text-transform: uppercase;
  font-weight: $fontWeightMedium;
}
