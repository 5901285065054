@import '../styles/globals.scss';

.title {
  padding: 0;
  flex-direction: column;
}

.header {
  color: $primary-contrastText;
  padding: 0.75*$shape-cardPadding $shape-cardPadding;
  font-family: $subtitle1-fontFamily;
  font-size: 1.1*$subtitle1-fontSize;
  font-weight: $fontWeightBold;
  line-height: 1.1*$subtitle1-lineHeight;
  letter-spacing: 1.1*$subtitle2-letterSpacing;
  background: linear-gradient(0deg,
    $primary-light3 0%,
    $primary-light2 100%
  ) $primary-light2;
}

.content {
  position: relative;
  padding: 0;
  padding-top: 0 !important;
  line-height: 0;
}

.image {
  width: $shape-tourScreenshotWidth;
  height: $shape-tourScreenshotHeight;
}

.loadingImage {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 0;
  height: 0;
}
