@import '../styles/globals.scss';

.chart,
.chartPreview {
  display: flex;
  flex-grow: 1;
}

.chart {
  min-height: 24rem;
  max-height: 24rem;
  min-width: 42rem;
  margin: 2.25*$spacing 0 1*$spacing 0;
}

.chartPreview {
  min-height: 8rem;
  max-height: 8rem;
  canvas {
    cursor: pointer !important;
  }
}

.tooltipHeader {
  font-family: $h4-fontFamily;
  font-size: $h4-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $h4-lineHeight;
  letter-spacing: $h4-letterSpacing;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1*$spacing 1*$spacing 1.5*$spacing 1*$spacing;
}

.tooltipLabel {
  padding-left: 2*$spacing;
}

.tooltipRow {
  padding: 0.5*$spacing;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: $h5-fontFamily;
  font-size: $h5-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $h5-lineHeight;
  letter-spacing: $h5-letterSpacing;
}

.tooltipFirst {
  padding: 0.5*$spacing;
  display: flex;
  align-items: center;
}

.tooltipBars {
  padding: 0.5*$spacing;
  display: flex;
  align-items: center;
  min-width: 14rem;
}

.tooltipBarContainer {
  flex: 1 0 0;
  display: flex;
  flex-direction: column;
}

.tooltipLine {
  display: flex;
  align-items: center;
}

.tooltipBar {
  height: 1*$h5-fontSize;
  border-radius: $shape-smallBorderRadius;
}

.tooltipBarLabel {
  padding-left: 1*$spacing;
  font-family: $h6-fontFamily;
  font-size: $h6-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $h6-lineHeight;
  letter-spacing: $h6-letterSpacing;
}
