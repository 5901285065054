@import '../styles/globals.scss';

.content {
  padding-top: 2.25*$spacing;
}

.container {
  border-top: $shape-borderWidth solid $delimiterColor;
  flex-direction: column;
  @media (min-width: $breakpoints-values-lg) {
    flex-direction: row;
  }
}
