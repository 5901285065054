@import '../styles/globals.scss';

.outlinedRoot {
  background-color: inherit;
}

.outlined {
  background: $background-paper !important;
  padding-right: 0.75*$spacing;
}

.root,
.input {
  font-size: $h3-fontSize;
  font-weight: $h3-fontWeight;
  // TODO: align placeholder-label vertically:
  // padding-top: 1.25*$spacing;
  // padding-bottom: 1.25*$spacing;
}

.input {
  height: 1.25*$h3-fontSize;
}
