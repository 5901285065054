@import '../styles/globals.scss';

.topPanel {
  display: flex;
  align-items: center;
}

.textArea {
  width: 100%;
  margin-top: 1.5*$spacing;
  padding: 1*$spacing;
  border-color: $greys-border;
  border-radius: $shape-borderRadius;
  font-family: $body1-fontFamily;
  font-size: $body1-fontSize;
  font-weight: $body1-fontWeight;
  line-height: $body1-lineHeight;
  letter-spacing: $body1-letterSpacing;
}

.saveBtn,
.shareBtn {
  margin-top: -0.75*$spacing;
}
.saveBtn {
  margin-left: -1.5*$spacing;
}
.shareBtn {
  margin-left: 0.75*$spacing;
}

.applyBtn {
  padding: 1*$spacing 6*$spacing;
}
