@import '../styles/globals.scss';

.item {
  min-width: 18rem;
  max-width: 22rem;
  padding: $shape-cardVSpacing $shape-cardSpacing !important;
  font-family: $body1-fontFamily;
  font-weight: $body1-fontWeight;
  font-size: $body1-fontSize;
  line-height: $body1-lineHeight;
  letter-spacing: $body1-letterSpacing;
}

.card {
  border: $shape-borderWidth solid $info-lighter;
  display: flex;
  flex-direction: column;
  forced-color-adjust: none;
}

.divider {
  border-color: $greys-barBorder;
  margin-left: 1*$spacing;
  margin-right: 1*$spacing;
}

$fontSize: 0.5*($h4-fontSize + $subtitle1-fontSize);

.link {
  padding-left: 1.75*$spacing;
  padding-right: 1.75*$spacing;
}

.circular svg {
  circle {
    stroke: $secondary-main;
  }
}

.circle svg {
  circle {
    stroke: $greys-courseCircleBg;
    fill: $greys-iconBg;
  }
}

.features {
  height: 10*$body2-fontSize;
  min-height: 10*$body2-fontSize;
  max-height: 10*$body2-fontSize;
  overflow-y: auto;
}

.skillName {
  font-size: 0.95*$body1-fontSize;
}

.videoBtn {
  padding: 0.25*$spacing 1.75*$spacing;
  min-width: 1.5*$h2-fontSize;
  font-size: $h2-fontSize;
}
