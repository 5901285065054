@import '../styles/globals.scss';

.chart,
.chartPreview {
  display: flex;
  flex-grow: 1;
}

.chart {
  min-height: 18rem;
  max-height: 18rem;
}

.chartPreview {
  min-height: 8rem;
  max-height: 8rem;
  canvas {
    cursor: pointer !important;
  }
}

.tooltip {
  display: flex;
  flex-direction: column;
  padding: 1*$spacing 1.75*$spacing 0.5*$spacing 1.75*$spacing;
}

.tooltipTitle {
  font-family: $subtitle2-fontFamily;
  font-size: $subtitle2-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $subtitle2-lineHeight;
  letter-spacing: $subtitle2-letterSpacing;
  padding-bottom: 1.75*$spacing;
}

.tooltipSubheader {
  font-family: $caption-fontFamily;
  font-size: $caption-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $caption-lineHeight;
  letter-spacing: $caption-letterSpacing;
  padding-bottom: 0.25*$spacing;
  text-transform: uppercase;
  color: $info-caption;
}

.tooltipLine {
  font-family: $h5-fontFamily;
  font-size: $h5-fontSize;
  font-weight: $fontWeightMedium;
  line-height: $h5-lineHeight;
  letter-spacing: $h5-letterSpacing;
  padding-bottom: 0.75*$spacing;
}
