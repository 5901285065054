@import '../styles/globals.scss';

.chart {
  flex-grow: 1;
  width: 100%;
  min-height: 800px;
  // padding: 1*$spacing 2*$spacing 0 1.5*$spacing;
}

.reset {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  top: 1*$spacing;
  right: 3.5*$spacing;
  z-index: $zIndex-level1;
}
