@import '../styles/globals.scss';

.btn {
  padding: 1.25*$spacing 6*$spacing 1*$spacing 6*$spacing;
}

.discardBtn {
  padding: 0.5*$spacing 4*$spacing;
}

.ctrlPanel {
  justify-content: flex-end;
}
