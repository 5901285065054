@import '../styles/globals.scss';

.listItem,
.checkedItem {
  padding-top: 1.25*$spacing;
  padding-bottom: 1.25*$spacing;
  font-family: $subtitle2-fontFamily;
  font-size: $subtitle2-fontSize;
  font-weight: $fontWeightBold;
  line-height: $subtitle2-lineHeight;
  letter-spacing: $subtitle2-letterSpacing;
}
.listItem {
  color: $secondary-main;
}
.checkedItem {
  color: $text-secondary;
}

.completedStep,
.pendingStep {
  svg {
    font-size: 0.5*($h2-fontSize + $h3-fontSize);
  }
}
.completedStep {
  color: $misc-stepFinished;
  background: $background-paper;
}

.pendingStep {
  color: $primary-contrastText;
  background: $primary-main;
}
