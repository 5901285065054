@import '../styles/globals.scss';

.dialogTitle,
.dialogLoading{
  display: flex;
  align-items: center;
  padding-top: 3*$spacing;
}
.dialogTitle {
  border-bottom: $shape-thickBorderWidth solid $delimiterColor;
}
.dialogLoading {
  color: $action-disabledBackground;
}

.dialogContent {
  padding: 0;
  padding-top: 0 !important;
}

.moreButton {
  margin: 1.5*$spacing 4*$spacing 0 1.5*$spacing;
}

.legend {
  padding: 1*$spacing $shape-cardPadding 0 $shape-cardPadding;
}
