@import '../styles/globals.scss';

.dropArea {
  min-height: 9*$spacing;
}

.dropUniform {
  min-height: 7*$spacing;
}

.placeholder {
  font-weight: $fontWeightRegular;
}

.reset {
  margin-right: 0.75*$spacing;
  align-self: center;
  padding: 1.5*$spacing 2.5*$spacing;
}
