@import '../styles/globals.scss';

.section {
  padding-top: 3*$spacing !important;
  padding-bottom: 5*$spacing !important;
}

.button {
  padding: 1.125*$spacing 6*$spacing;
}

.longButton {
  padding: 1.125*$spacing 2*$spacing;
}
