@import '../styles/globals.scss';

.titleRow {
  height: 5.25*$subtitle2-fontSize;
  min-height: 5.25*$subtitle2-fontSize;
  max-height: 5.25*$subtitle2-fontSize;
  overflow: hidden;
}

.nextIcon {
  margin-right: 1*$spacing;
}
