@import '../styles/globals.scss';

.nameContainer {
  height: 2.3*$subtitle2-fontSize;
  min-height: 2.3*$subtitle2-fontSize;
  max-height: 2.3*$subtitle2-fontSize;
}

.nameRow {
  max-height: 2.3*$subtitle2-fontSize;
  overflow: hidden;
}
