@import '../styles/globals.scss';

.title {
  font-size: 2rem;
  line-height: 1.4;
  z-index: $zIndex-level1; // TODO: safe to remove?
}

.intro {
  font-weight: $fontWeightRegular;
  line-height: 1.7;
  z-index: $zIndex-level1; // TODO: safe to remove?
}

.button {
  padding: 2*$spacing 9*$spacing;
}

.buttonPending {
  padding: 2*$spacing 7.625*$spacing;
}

.privacyLink {
  font-weight: $fontWeightRegular;
  z-index: $zIndex-level1; // TODO: safe to remove?
}

.textLink {
  color: $primary-contrastText;
}
