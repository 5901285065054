@import '../styles/globals.scss';

.header {
  padding-right: 8*$spacing !important;
}

.content {
  padding-bottom: 7*$spacing !important;
}

.completed {
  padding-bottom: 0 !important;
}

.details {
  font-family: $h4-fontFamily;
  font-size: $h4-fontSize;
  font-weight: $fontWeightRegular;
  line-height: $h4-lineHeight;
  letter-spacing: $h4-letterSpacing;
}

.detailsButton {
  padding: 1*$spacing 2.5*$spacing;
  margin: 2*$spacing 3.75*$spacing;
}
