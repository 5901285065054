@import '../styles/globals.scss';

.bg {
  background: linear-gradient(60deg,
    $background-chipLeft 0%,
    $background-chipRight 100%
  ) $background-chipLeft;
}

.headlines {
  font-family: $body2-fontFamily;
  font-weight: $fontWeightMedium;
  font-size: $body2-fontSize;
  line-height: $body2-lineHeight;
  letter-spacing: $body2-letterSpacing;
  text-transform: uppercase;
  text-align: center;
}

.arrows svg {
  // g path {
  //   stroke: url(#jobMoveArrowGradient) $success-dark;
  // }
  defs marker path {
    fill: $success-dark;
  }
}
