@import '../styles/globals.scss';

.filtersPadding {
  padding-top: 1*$spacing;
  padding-bottom: 0.75*$spacing;
}

.minSkillsSelector {
  margin-right: 1.5*$spacing;
}
