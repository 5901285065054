@import '../styles/globals.scss';

.card {
  height: 100%; // TODO: make all cards in a row equal in height
}

.icon {
  margin-right: 1*$spacing;
}

.itemRelative {
  forced-color-adjust: none;
  position: relative;
  align-self: stretch;
  padding: $shape-cardVSpacing $shape-cardSpacing 0 $shape-cardSpacing;
}
